import { CdkMenu, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgClass, NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import {
  UseNavigationIconDirective,
  UseUIBasicIconDirective,
} from '../atoms/icons';
import { DropdownMenuComponent } from './dropdown-menu.component';
import {
  CategoryName,
  CategoryNameTranslation,
  getCategories,
  getCategoriesKeys,
  ItemsNavigationHelper,
} from './items-navigation.helper';
export interface ItemNavigation {
  id: number;
  selected: boolean;
  title: string;
  date: DateTime;
}

@Component({
  selector: 'sd-items-navigation',
  imports: [
    NgFor,
    NgClass,
    UseUIBasicIconDirective,
    UseNavigationIconDirective,
    DropdownMenuComponent,
    TranslateModule,
    CdkMenuTrigger,
    CdkMenu,
  ],
  providers: [ItemsNavigationHelper],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-col gap-6 h-full">
      <div class="flex flex-col h-full px-2 gap-1">
        @if (!items().length) {
        <span class="category">{{ getTranslatedCategory('today') }}</span>
        <div
          class="flex flex-col gap-2 items-center justify-center h-full py-10 mx-2 px-2 bg-neutral-100 rounded-2xl"
          [class.h-full]="isMobile()"
        >
          <svg
            sdUseNavigationIcon="new-chat"
            class="size-5 text-neutral-400"
          ></svg>
          <span class="typo-caption text-neutral-500 text-center">{{
            'NAVIGATION_ITEMS.START_FIRST_CHAT' | translate
          }}</span>
        </div>
        } @else {
        <ng-container *ngFor="let category of categoriesKeys()">
          <span class="category">{{ getTranslatedCategory(category) }}</span>
          @for (item of categories()[category]; track item.id) {
          <div
            class="relative flex items-center justify-between gap-4 tablet-landscape:typo-p3 typo-p2 w-full tablet-landscape:h-10 h-14 px-2 py-2 rounded cursor-pointer hover:bg-primary-500/5 group"
            [ngClass]="{
            'bg-primary-500/10': item.selected,
          }"
            (click)="selectItem(item)"
          >
            <span
              [title]="item.title"
              class="max-w-full whitespace-nowrap overflow-hidden text-ellipsis py-2"
              [class.font-semibold]="item.selected"
              >{{ item.title }}</span
            >
            <div class="relative">
              @if (!isMobile()) {
              <svg
                (click)="$event.stopPropagation()"
                [cdkMenuTriggerFor]="menu"
                sdUseUIBasicIcon="more-horizontal"
                class="size-6 [&[aria-expanded='true']]:text-primary-500 [&[aria-expanded='true']]:opacity-100 text-neutral-700 focus:outline-none opacity-0 group-hover:opacity-100 transition-opacity"
              ></svg>
              }
              <!-- DROPDOWN MENU -->
              <ng-template #menu>
                <sd-dropdown-menu
                  cdkMenu
                  [options]="[
                    {
                      label: translateService.instant(
                        'CHAT.DROPDOWN_MENU.SAVE_ASSISTANT'
                      ),
                      icon: 'save',
                      action: 'save',
                      disabled: true
                    },
                    {
                      label: translateService.instant(
                        'CHAT.DROPDOWN_MENU.RENAME'
                      ),
                      icon: 'edit',
                      action: 'rename',
                      disabled: true
                    },
                    {
                      label: translateService.instant(
                        'CHAT.DROPDOWN_MENU.ARCHIVE'
                      ),
                      icon: 'archive',
                      action: 'archive',
                      disabled: true
                    },
                    {
                      label: translateService.instant(
                        'CHAT.DROPDOWN_MENU.DELETE'
                      ),
                      icon: 'delete',
                      action: 'delete',
                      error: true
                    }
                  ]"
                  (triggerAction)="onTriggerAction($event)"
                ></sd-dropdown-menu>
              </ng-template>
            </div>
          </div>
          }
        </ng-container>
        }
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply h-full;
        .category {
          @apply typo-p3 font-semibold tablet-landscape:typo-caption tablet-landscape:font-semibold text-neutral-500 py-1 px-2;
        }
      }
    `,
  ],
})
export class ItemsNavigationComponent {
  private readonly helper = inject(ItemsNavigationHelper);
  public readonly translateService = inject(TranslateService);

  public readonly isMobile = input<boolean>(false);
  public readonly items = input.required<ItemNavigation[]>();
  public readonly itemClicked = output<ItemNavigation>();
  public readonly deleteClicked = output<string>();

  public readonly categories = computed<Record<string, ItemNavigation[]>>(() =>
    getCategories(this.items())
  );
  public readonly categoriesKeys = computed<CategoryName[]>(() =>
    getCategoriesKeys(this.categories())
  );

  public getTranslatedCategory(
    category: CategoryName
  ): CategoryNameTranslation {
    return this.helper.getCategoryNameTranslation(category);
  }

  public selectItem(item: ItemNavigation): void {
    const selectedItem = this.items().find((t) => t.id === item.id);

    if (selectedItem) {
      this.itemClicked.emit(selectedItem);
    }
  }

  // ------------------------------------
  // EVENTS
  // ------------------------------------
  public onTriggerAction(action: string) {
    this.deleteClicked.emit(action);
  }
}
