import { NgClass } from '@angular/common';
import { Component, effect, input } from '@angular/core';
import { SpinnerDirective } from '../atoms/spinner/spinner.directive';

@Component({
  selector: 'sd-voice-listening',
  imports: [NgClass, SpinnerDirective],
  styles: [
    `
      .bar-dot {
        @apply w-1 bg-shades-white transition-all duration-300 ease-in-out;
      }
      .dot {
        @apply h-1 rounded-full;
      }
      .bar {
        @apply rounded-sm;
      }
    `,
  ],
  template: `
    <div
      class="w-10 h-10 tablet-landscape:w-8 tablet-landscape:h-8 rounded-full flex justify-center items-center"
      [class.bg-primary-400]="!isLoading()"
    >
      <div class="flex justify-center w-12 h-8 space-x-0.5">
        @if (!isLoading()) { @for (i of [1, 2, 3]; track i) {
        <div class="flex flex-col justify-center items-center h-full">
          <div
            [ngClass]="{ dot: !isSpeaking(), bar: isSpeaking() }"
            class="bar-dot"
            #bar
          ></div>
        </div>
        } } @else {
        <div class="flex flex-col justify-center items-center h-full">
          <div sdSpinner></div>
        </div>
        }
      </div>
    </div>
  `,
})
export class VoiceListeningComponent {
  public isSpeaking = input<boolean>();
  public isLoading = input<boolean>();

  constructor() {
    effect(() => {
      const isSpeaking = this.isSpeaking() ?? false;
      this.toggleAnimation(isSpeaking);
    });
  }

  toggleAnimation(isSpeaking: boolean) {
    const bars = document.querySelectorAll('.bar-dot');
    bars.forEach((bar) => {
      (bar as HTMLElement).style.height = isSpeaking ? '0.125rem' : '0.25rem';
      if (isSpeaking) {
        this.animateBar(bar as HTMLElement);
      }
    });
  }

  animateBar(bar: HTMLElement) {
    if (!this.isSpeaking()) return;
    const height = Math.random() * 0.8125 + 0.125; // 2px to 15px
    bar.style.height = `${height}rem`;
    setTimeout(() => this.animateBar(bar), 150);
  }
}
