import {
  ClassProvider,
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  output,
  Signal,
  signal,
  Type,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UseUIBasicIconDirective } from '../../atoms';
import { IconButtonDirective, TooltipDirective } from '../../molecules';
import {
  VoiceToTextApiKeyServiceInterface,
  VoiceToTextServiceInterface,
} from './interfaces';
import { VOICE_TO_TEXT_API_KEY_SERVICE, VOICE_TO_TEXT_SERVICE } from './tokens';

export function provideVoiceToTextService(
  VoiceToTextService: Type<VoiceToTextServiceInterface>
): ClassProvider {
  return {
    provide: VOICE_TO_TEXT_SERVICE,
    useClass: VoiceToTextService,
  };
}

export function provideVoiceToTextApiKeyService(
  VoiceToTextApiKeyService: Type<VoiceToTextApiKeyServiceInterface>
): ClassProvider {
  return {
    provide: VOICE_TO_TEXT_API_KEY_SERVICE,
    useClass: VoiceToTextApiKeyService,
  };
}

@Component({
  selector: 'sd-voice-to-text',
  imports: [
    TranslateModule,
    TooltipDirective,
    IconButtonDirective,
    UseUIBasicIconDirective,
  ],
  template: `
    <button
      sdIconButton
      variant="icon"
      [color]="voiceToTextService.isRecording() ? 'primary' : 'neutral'"
      [size]="isMobile() ? 'sm' : 'xs'"
      [disabled]="isDisabled()"
      (click)="toggleRecording()"
      [sdTooltip]="!isDisabled() ? tooltip() : ''"
    >
      <svg sdUseUIBasicIcon="voice"></svg>
    </button>
  `,
})
export class VoiceToTextComponent implements OnInit {
  private readonly translateService = inject(TranslateService);
  protected readonly voiceToTextService = inject<VoiceToTextServiceInterface>(
    VOICE_TO_TEXT_SERVICE
  );

  public readonly isDisabled = input<boolean>(false);
  public readonly recordingClicked = output<boolean>();
  public readonly transcriptionReceived = output<string>();
  public readonly speakingStatusChanged = output<boolean>();
  public readonly handleVoiceToTextReady = output<boolean>();

  protected readonly TABLET_LANDSCAPE_WIDTH = 905;
  public readonly isMobile = signal(
    window.innerWidth <= this.TABLET_LANDSCAPE_WIDTH
  );

  constructor() {
    effect(() => {
      this.recordingClicked.emit(this.voiceToTextService.isRecording());
    });

    effect(() => {
      this.transcriptionReceived.emit(this.voiceToTextService.transcription());
    });

    effect(() => {
      this.speakingStatusChanged.emit(this.voiceToTextService.isSpeaking());
    });

    effect(() => {
      this.handleVoiceToTextReady.emit(this.voiceToTextService.isReady());
    });
  }

  public readonly tooltip: Signal<string> = computed(
    () =>
      (this.voiceToTextService.isRecording()
        ? this.translateService.instant('PROMPT_INPUT.TOOLTIP.STOP')
        : this.translateService.instant(
            'PROMPT_INPUT.TOOLTIP.VOICE_TO_TEXT'
          )) as string
  );

  ngOnInit() {
    void this.voiceToTextService.init();
  }

  public toggleRecording() {
    if (this.voiceToTextService.isRecording()) {
      this.voiceToTextService.stopRecording();
      this.recordingClicked.emit(false);
    } else {
      void this.voiceToTextService.startRecording();
      this.recordingClicked.emit(true);
    }
  }
}
