import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  input,
  model,
  output,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UseArrowIconDirective } from '../atoms/icons/arrow.directive';
import { UseUIBasicIconDirective } from '../atoms/icons/ui-basic.directive';
import { IconButtonDirective } from '../molecules/icon-button.directive';
import { TooltipDirective } from '../molecules/tooltip.directive';
import { VoiceListeningComponent } from './voice-listening.component';
import { VoiceToTextComponent } from './voice-to-text/voice-to-text.component';
@Component({
  selector: 'sd-prompt-input',
  imports: [
    FormsModule,
    TranslateModule,
    IconButtonDirective,
    UseArrowIconDirective,
    UseUIBasicIconDirective,
    VoiceToTextComponent,
    VoiceListeningComponent,
    NgClass,
    TooltipDirective,
  ],
  template: `
    <div
      #container
      class="grid grid-cols-[min-content_auto_min-content] w-full
        border border-neutral-200
        tablet-landscape:shadow-sm
        min-h-14
        tablet-landscape:min-h-12
        "
      [ngClass]="{
        ' hover:border-primary-300 focus:border-primary-500 focus:ring-primary-500':
          !disabled(),
        'opacity-60': disabled()
      }"
      [sdTooltip]="tooltipText() | translate"
      [sdTooltipAlwaysOn]="isMessageTooLong()"
    >
      <div class="flex items-end pl-3 py-3 tablet-landscape:py-2">
        @if (hasVoiceToText() && isRecording()) {
        <sd-voice-listening
          [isSpeaking]="isSpeaking()"
          [isLoading]="!isVoiceToTextReady()"
        ></sd-voice-listening>
        } @else {
        <button
          sdIconButton
          variant="filled"
          color="neutral"
          [size]="isMobile() ? 'sm' : 'xs'"
          [disabled]="true"
        >
          <svg sdUseUIBasicIcon="plus"></svg>
        </button>
        }
      </div>
      <div class="flex items-center py-3">
        <textarea
          #textarea
          [(ngModel)]="userInput"
          (input)="onInput()"
          (keydown)="handleKeydown($event)"
          (paste)="onPaste()"
          class="
            min-h-6
            max-h-80
            w-full
            focus:outline-none
            pl-3
            typo-p2
            leading-6
            box-content
            bg-transparent
            placeholder:text-neutral-500
            text-neutral-700
            resize-none
            overflow-y-auto
            focus:caret-primary-500
            disabled:cursor-not-allowed
            custom-scrollbar"
          [disabled]="isRecording() || disabled()"
          placeholder="{{ inputPlaceholder() | translate }}"
        ></textarea>
      </div>
      <div class="flex items-end gap-2 pl-3 pr-2 py-3 tablet-landscape:py-2">
        @if (hasVoiceToText()) {
        <sd-voice-to-text
          [isDisabled]="(!isSubmitDisabled() && !isRecording()) || disabled()"
          (transcriptionReceived)="onTranscription($event)"
          (recordingClicked)="setIsRecording($event)"
          (speakingStatusChanged)="isSpeaking.set($event)"
          (handleVoiceToTextReady)="isVoiceToTextReady.set($event)"
        ></sd-voice-to-text>
        }
        <button
          sdIconButton
          variant="filled"
          color="primary"
          [size]="isMobile() ? 'sm' : 'xs'"
          [disabled]="isSubmitDisabled() || isRecording()"
          (click)="submitPrompt()"
        >
          <svg sdUseArrowIcon="arrow-up"></svg>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .custom-scrollbar {
        scrollbar-width: none;
      }

      :host {
        @apply w-full;
      }
    `,
  ],
})
export class PromptInputComponent implements AfterViewInit {
  @ViewChild('textarea') textarea: ElementRef | undefined;
  @ViewChild('container') container: ElementRef | undefined;

  public readonly MAX_LENGTH = 12000;

  public readonly disabled = input<boolean>(false);
  public readonly hasVoiceToText = input<boolean>(false);
  public readonly promptSubmitted = output<string>();

  public readonly isMessageTooLong = computed(() => {
    return this.userInput().length > this.MAX_LENGTH;
  });

  public readonly tooltipText = computed(() => {
    return this.isMessageTooLong()
      ? 'PROMPT_INPUT.TOOLTIP.MESSAGE_TOO_LONG'
      : '';
  });

  public readonly isSubmitDisabled = computed(() => {
    return (
      this.disabled() ||
      this.userInput().trim() === '' ||
      this.isMessageTooLong()
    );
  });

  public readonly userInput = model('');
  public readonly isRecording = signal(false);
  public readonly isSpeaking = signal(false);
  public readonly isVoiceToTextReady = signal(false);

  private hasStartedRecordingAtLeastOnce = false;

  private readonly MIN_HEIGHT_6_TW_TO_PX = 24; // 1.5rem * 16
  private readonly MAX_HEIGHT_80_TW_TO_PX = 320 - 24; // h-80 - py-3
  private readonly TABLET_LANDSCAPE_WIDTH = 905;

  private readonly inputSubject = new Subject<void>();

  public readonly isMobile = signal(
    window.innerWidth <= this.TABLET_LANDSCAPE_WIDTH
  );

  public readonly inputPlaceholder: Signal<string> = computed(() => {
    return this.isRecording()
      ? 'PROMPT_INPUT.LISTENING'
      : 'PROMPT_INPUT.PLACEHOLDER';
  });

  constructor() {
    this.inputSubject.pipe(debounceTime(50)).subscribe(() => {
      setTimeout(() => this.adjustTextareaHeight(), 0);
    });
  }

  ngAfterViewInit() {
    this.adjustTextareaHeight();
  }

  onInput() {
    this.inputSubject.next();
  }

  onPaste() {
    setTimeout(() => this.adjustTextareaHeight(), 0);
  }

  adjustTextareaHeight(reset = false) {
    const textarea = this.textarea?.nativeElement as HTMLTextAreaElement;
    const container = this.container?.nativeElement as HTMLTextAreaElement;

    if (textarea?.value && !reset) {
      if (
        textarea.scrollHeight > this.MIN_HEIGHT_6_TW_TO_PX ||
        textarea.value.includes('\n')
      ) {
        container.classList.remove('rounded-full');
        container.classList.add('rounded-3xl');
      }

      textarea.style.height = `${this.MIN_HEIGHT_6_TW_TO_PX}px`;
      textarea.style.height = `${Math.min(
        textarea.scrollHeight,
        this.MAX_HEIGHT_80_TW_TO_PX
      )}px`;
    } else {
      container.classList.remove('rounded-3xl');
      container.classList.add('rounded-full');
      textarea.style.height = `${this.MIN_HEIGHT_6_TW_TO_PX}px`;
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (!this.isSubmitDisabled() && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.submitPrompt();
    }
  }

  submitPrompt() {
    this.promptSubmitted.emit(this.userInput());
    this.clear();
  }

  clear() {
    this.userInput.set('');
    this.adjustTextareaHeight(true);
  }

  setIsRecording(isRecording: boolean) {
    this.isRecording.set(isRecording);
    if (isRecording) {
      this.hasStartedRecordingAtLeastOnce = true;
    }
  }

  onTranscription(transcription: string) {
    if (this.hasStartedRecordingAtLeastOnce) {
      const capitalizedTranscription =
        this.capitalizeFirstLetter(transcription);
      this.userInput.set(capitalizedTranscription);
      setTimeout(() => this.adjustTextareaHeight(), 0);
    }
  }

  private capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
