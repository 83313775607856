import { CdkMenuItem } from '@angular/cdk/menu';
import { Component, input, output } from '@angular/core';
import * as fromSeriousSystem from '../atoms';
import { OptionMenu, OptionMenuComponent } from './option-menu.component';

@Component({
  selector: 'sd-dropdown-menu',
  imports: [
    fromSeriousSystem.UseUIBasicIconDirective,
    OptionMenuComponent,
    CdkMenuItem,
  ],
  template: `
    <div
      class="flex flex-col w-fit bg-shades-white shadow-md rounded-md border border-neutral-200"
    >
      @for (option of options(); track option.label) {
      <sd-option-menu
        cdkMenuItem
        [option]="option"
        (triggerAction)="onTriggerAction($event)"
      ></sd-option-menu>
      }
    </div>
  `,
  styles: [``],
})
export class DropdownMenuComponent {
  public readonly options = input.required<OptionMenu[]>();
  public readonly triggerAction = output<string>();

  public onTriggerAction(action: string) {
    this.triggerAction.emit(action);
  }
}
