import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import * as fromSeriousSystem from '../atoms';

export type OptionMenuIcon = 'save' | 'edit' | 'archive' | 'delete';

export interface OptionMenu {
  label: string;
  icon?: OptionMenuIcon;
  action: string;
  disabled?: boolean;
  error?: boolean;
  separator?: boolean;
}

@Component({
  selector: 'sd-option-menu',
  imports: [NgClass, fromSeriousSystem.UseUIBasicIconDirective],
  template: `
    <button
      class="
        flex flex-row gap-2 items-end
        p-4 h-14 tablet-landscape:h-12 tablet-landscape:w-48 w-full

        typo-p3
        active:font-semibold
        disabled:opacity-50 disabled:cursor-not-allowed disabled:text-neutral-700 disabled:hover:bg-transparent disabled:active:font-normal
        [&>svg]:size-5
      "
      [ngClass]="{
        'hover:bg-primary-500/5 active:bg-primary-500/10 active:text-neutral-700 ':
          !option().error,
        'text-error-600 hover:bg-error-50 active:bg-error-50 active:text-error-600':
          option().error,
        'border-t border-neutral-200': option().separator,
        'rounded-md': !option().separator
      }"
      [disabled]="option().disabled"
      (click)="onTriggerAction(option().action)"
    >
      @if (option().icon) {
      <svg
        [sdUseUIBasicIcon]="option().icon!"
        [class.text-neutral-700]="!option().error"
        [class.text-error-500]="option().error"
      />
      }
      <span
        class="max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
        >{{ option().label }}</span
      >
    </button>
  `,
  styles: [``],
})
export class OptionMenuComponent {
  public readonly option = input.required<OptionMenu>();
  public readonly triggerAction = output<string>();

  public onTriggerAction(action: string) {
    this.triggerAction.emit(action);
  }
}
